import { ReactComponent as DownloadIcon } from '../assets/Download.svg';

const Header = ({ name, job, email, phone, address }) => {
  const handleDownloadClick = () => {
    window.print();
  };
  return (
    <div className="
      p-3 sm:p-8 mb-8 relative
      print:w-1/2 print:float-left
    ">
      <span className="
        absolute inset-x-0 top-0 h-2/3 bg-primary z-0
        print:bg-transparent
      " />
      <div className="
        max-w-xl mx-auto w-full bg-white p-6 sm:p-16 shadow-xl relative z-10
        print:shadow-none print:p-0 print:max-w-full
      ">
        <div className="
          flex items-end sm:-mb-4 whitespace-nowrap
          print:mb-0
        ">
          <h1 className="
            font-title text-5xl
            sm:text-8xl sm:-ml-38
            print:ml-0
          ">
            {name}
          </h1>
        </div>
        <h2 className="
          font-subtitle tracking-wide text-lg text-white whitespace-nowrap px-2 py-1 sm:-ml-2 inline-block bg-primary
          print:ml-0
        ">
          {job}
        </h2>
        <button
          className="
            absolute right-2 bottom-2 p-2 rounded-full hover:bg-gray-100
            hidden sm:inline-block print:hidden
          "
          onClick={handleDownloadClick}>
          <DownloadIcon className="h-6" />
        </button>
      </div>
    </div>
  );
};
export default Header;
