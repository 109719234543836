const Section = ({ name, hideNameOnPrint, halfOnPrint, children }) => (
  <div className={`
    max-w-xl w-full mx-auto px-3 sm:px-6 relative mb-16
    print:max-w-full print:float-left print:w-${halfOnPrint ? '1/2' : 'full'} print:mb-12
  `}>
    <p className={`
      sm:absolute sm:top-0 sm:right-full sm:mr-6 mb-4 sm:mb-0 font-title text-primary text-3xl opacity-50
      ${hideNameOnPrint && 'print:hidden'}
    `}>
      {name}
    </p>
    {children}
  </div>
);

export default Section;