const Item = ({
  title,
  subtitle,
  href,
  legend,
  from,
  to = 'Maintenant',
  desc,
  badges,
  children,
}) => (
  <div className="mb-8 last:mb-0 print:mb-6">
    <div className="flex flex-col sm:flex-row sm:items-end justify-between pb-1 relative">
      <h3 className="
        text-3xl font-title
        print:text-xl
      ">
        {href
          ? <a href={href} target="_blank" rel="noreferrer noopener">{title}</a>
          : title
        }
        {legend && <span className="text-gray-200"> {legend}</span>}
      </h3>
      {from && to &&
        <p className="
          text-xs font-light text-gray-300
          print:absolute print:right-0 print:bottom-1.5
        ">
          {from} &rarr; {to}
        </p>
      }
    </div>
    {subtitle &&
      <div className="border-0 border-t border-solid border-primary mb-4 last:mb-0">
        <h4 className="
          font-subtitle tracking-wide text-white py-1 inline-block -mt-px
          border-0 border-l-8 border-solid border-primary relative
        ">
          <span className="absolute w-full h-8 left-0 top-0 bg-primary z-0" />
          <span className="bg-primary py-1.25 pr-2 relative z-10">{subtitle}</span>
        </h4>
      </div>
    }
    {desc && <p className="text-xs text-gray-500 font-light mb-1 last:mb-0">{desc}</p>}
    {children}
    {badges &&
      badges.map((group, i) => <div className="-mb-1" key={i}>
        {group?.map((badge, j) => <span key={j} className="inline-block text-xxs text-gray-600 bg-gray-200 px-1.5 py-1 rounded-full mr-1 mb-1">{badge}</span>)}
      </div>)
    }
  </div>
);

export default Item;