const Config = {
  name: 'Hugo Socié',
  job: 'Lead développeur front-end Vue.js',

  email: 'hugosocie@gmail.com',
  phone: '06 72 60 92 47',
  address: '6 rue Desaugiers, 69007 Lyon',
  folio: 'www.hugosocie.fr',
  linkedin: 'www.linkedin.com/in/hugo-socié-006426aa/',

  sections: [
    {
      name: 'Expériences',
      items: [
        {
          title: 'Potager City',
          subtitle: 'Lead Développeur front-end Vue.js',
          href: 'https://www.potagercity.fr/',
          legend: 'CDI',
          from: 'Septembre 2018',
          to: undefined,
          desc: 'Depuis mon arrivé à Potager City, je suis en charge toute la partie front web & web mobile, depuis la participation à l\'élaboration du besoin et des maquettes, en passant par le développement des nouvelles fonctionnalités, la maintenance de l\'existant et jusqu\'à la mise en production.',
          badges: [
            ['Vue.js', 'Vue Router', 'Vuex', 'es6', 'Webpack', 'SCSS', 'tailwindcss', 'Storybook'],
          ],
        },
        {
          title: 'Freelance',
          subtitle: 'Développeur front-end',
          from: 'Octobre 2014',
          to: 'Juin 2019',
          desc: 'Ma période en tant que freelance m\'a donné l\'opportunité de travailler sur de nombreux projets différents, me permettant ainsi de perfectionner mes compétences techniques & graphiques et de m\'initier à Vue.js.',
          badges: [
            ['HTML', 'SCSS', 'JS', 'Vue.js', 'Gulp', 'Wordpress', 'PHP'],
          ],
        },
        {
          title: 'Cinémur',
          subtitle: 'Développeur front-end',
          href: 'https://cinemur.pro/casestudies/cinemur',
          legend: 'Alternance',
          from: 'Juin 2013',
          to: 'Octobre 2014',
          desc: 'Participation au développement de la plateforme web & web mobile Cinémur. C\'est véritablement ici que j\'ai fais mes armes en tant que développeur front-end, c\'est donc avec une émotion particulière que j\'ai appris l\'arrêt du service.',
          badges: [
            ['HTML', 'SCSS', 'JS', 'JQuery', 'PHP', 'Twig'],
          ],
        },
      ],
    },
    {
      name: 'Formations',
      items: [
        {
          title: 'HETIC',
          subtitle: 'Hautes Études des Technologies de l\'Information et de la Communication',
          legend: 'BAC +5',
          from: '2011',
          to: '2014',
        },
        {
          title: 'IUT de Troyes',
          subtitle: 'DUT SRC & Licence 2IMI',
          legend: 'BAC +3',
          from: '2008',
          to: '2011',
        },
      ],
    },
    {
      name: 'Compétences',
      items: [
        {
          legend: 'Non exhaustive...',
          badges: [
            ['Vue.js', 'Vue Router', 'Vuex', 'JavaScript', 'es6', 'JQuery'],
            ['CSS', 'SCSS', 'LESS'],
            ['Github', 'Bitbucket'],
            ['Photoshop', 'Sketch', 'Figma'],
          ],
        },
        {
          legend: 'Et à venir...',
          badges: [
            ['TypeScript', 'React', 'JSX', 'Redux'],
          ],
        },
      ],
    }
  ],

};
export default Config;