import Config from './config';

import Header from './components/Header';
import Section from './components/Section';
import Item from './components/Item';

const App = () => (
  <div className="App text-base text-gray-800 p-3 sm:p-6">

    <Header name={Config.name} job={Config.job} />

    <Section name="Coordonnées" hideNameOnPrint halfOnPrint>
      <Item>
        <p className="
          text-sm text-gray-500 font-light
          print:text-right print:mt-9
        ">
          <a href={`mailto:${Config.email}`} className="hover:text-gray-900">{Config.email}</a> • {Config.phone}<br />
          {Config.address}<br />
          <span className="hidden print:inline print:text-xxs">
            <a href={`https://${Config.folio}`} className="underline hover:text-gray-900">
              {Config.folio}
            </a> •
          </span>
          <a
            href={`https://${Config.linkedin}`}
            target="_blank"
            rel="noreferrer noopener"
            className="underline hover:text-gray-900 print:text-xxs">
            <span className="print:hidden">LinkedIn</span>
            <span className="hidden print:inline">{Config.linkedin}</span>
          </a>
        </p>
      </Item>
    </Section>

    <div className="clear-both hidden print:block" />

    {Config.sections?.map(({ name, items }, index) => (
      <Section name={name} halfOnPrint={index > 0} key={name}>
        {items?.map((item, index) => <Item {...item} key={index} />)}
      </Section>)
    )}

  </div>
);

export default App;
